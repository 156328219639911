<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="تعديل راتب">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="الموظف"
                  :state="errors.length > 0 ? false : null"
                >
             
             
                <v-select
                  v-model="employe"
                  :options="optionemploye"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="الراتب"
                  :state="errors.length > 0 ? false : null"
                >
             
                
                <b-form-input v-model="salary" type="number" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
           
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="updateSalary">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> تعديل الراتب</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BTab,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BTabs,
} from "bootstrap-vue";
export default {
  name: "",

  components: {
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BTabs,
    BTab,
    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      optionemploye: [],

      employe:'',

      salary: "",
    };
  },

  created() {
    this.getemploye();
    this.getJobs();
  },

  methods: {
  
    getJobs() {
      this.options = [];
      this.$http.get(`/api/v1/salaries/${this.$route.params.id}`).then((res) => {
        //console.log(res.data.data);
        this.salary = res.data.data.base_salary;
        this.employe=res.data.data.user_id;
      });
    },
  
    getemploye() {
      this.optionemploye=[]
      this.$http.get("/api/v1/get-all-users").then((res) => {
        //console.log(res);
        let employe = res.data;
        employe.forEach((el) => {
          this.optionemploye.push({ value: el.id, label: `${el.first_name} ${el.last_name} `
 });
        });
        //console.log(this.optionemploye);
      });
    },
    updateSalary() {
      let data = {
        base_salary: this.salary,
        user_id: this.employe,
      };
      let url=`/api/v1/salaries/${this.$route.params.id}`
       this.$http.put(url,data).then((res) => {
      
        //console.log(res);
        this.$swal({
          title: "تم التعديل بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
         
      });
    }
    //
  }
};
</script> 

<style scoped>
.form-control {
  font-weight: bold;
}
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

label {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
  font-size: 20px;
}
.align-left {
  margin-left: 750px;
}
.but {
  top: 40px;
}
</style>